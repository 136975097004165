import axios from "axios";
import { useCommonStore } from '@/store';
import { connectWallet } from './useCommon';

export const getUserNfts = async () => {

    const wallet = connectWallet();
    const walletAddress = wallet.publicKey.toBase58();

    const commonStore = useCommonStore();
    const network = commonStore.solanaNetwork;

    const webApiUrl = process.env.VUE_APP_SHYFT_API_URL;

    const data = await axios({
        method: 'get',
        url: webApiUrl + '/nft/read_all',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.VUE_APP_SHYFT_API_KEY,
        },
        params: {
            network: network,
            address: walletAddress
        }
    }).then(response => {
        return response.data.result;
    }).catch(error => {
        console.log(error);
    });

    console.log(data);

    return data;

}

export const getSelectedNft = async (nftAddresses) => {

    const commonStore = useCommonStore();
    const network = commonStore.solanaNetwork;

    const webApiUrl = process.env.VUE_APP_SHYFT_API_URL;

    const data = await axios({
        method: 'post',
        url: webApiUrl + '/nft/read_selected',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.VUE_APP_SHYFT_API_KEY,
        },
        data: {
            network: network,
            token_addresses: nftAddresses
        }
    }).then(response => {
        return response.data.result;
    }).catch(error => {
        console.log(error);
    });

    return data;


};

export const readNft = async (nftAddress) => {

    const commonStore = useCommonStore();
    const network = commonStore.solanaNetwork;

    const webApiUrl = process.env.VUE_APP_SHYFT_API_URL;

    const data = await axios({
        method: 'get',
        url: webApiUrl + '/nft/read',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.VUE_APP_SHYFT_API_KEY,
        },
        params: {
            network: network,
            token_address: nftAddress
        }
    }).then(response => {
        return response.data.result;
    }).catch(error => {
        console.log(error);
    });

    return data;

}