import bs58 from 'bs58';
import CryptoJS from 'crypto-js';
import { useWorkspace } from './useWorkspace';
import axios from 'axios';
import { useCommonStore } from '@/store';
import { ElMessage } from 'element-plus';

export const loginOrSignup = async () => {

    const { wallet } = useWorkspace();
    const wallet_address = wallet.publicKey.value.toBase58();
    const accessToken = CryptoJS.SHA256(wallet_address).toString(CryptoJS.enc.Hex);

    if (window.$cookies.get('accessToken') !== accessToken) {

        try {

            const signedMessages = await wallet.signMessage.value(new TextEncoder().encode(accessToken));
            const signature = bs58.encode(signedMessages);

            const commonStore = useCommonStore();
            const webApiUrl = commonStore.webApiUrl;

            await axios({
                url: webApiUrl + '/auth/login',
                method: 'post',
                data: {
                    wallet_address: wallet_address,
                    signature: signature,
                }
            }).then(response => {

                const result = response.data.Result;
                const userToken = result.access_token;

                // 紀錄 Wallet Name
                const walletName = wallet.wallet.value.adapter.name;

                window.$cookies.set('userToken', userToken, "1d", '/');
                window.$cookies.set('accessToken', accessToken, "1d", '/');
                window.$cookies.set('walletName', walletName, "1d", '/');

            }).catch(error => {
                console.log(error);
            });

            await addDropdownMenu();

        } catch (error) {

            wallet.disconnect();
            window.$cookies.remove('userToken');
            window.$cookies.remove('accessToken');

            const errorMessage = error.message;
            ElMessage({
                message: errorMessage,
                type: 'error',
                duration: 1500,
                showClose: true,
            });

        }

    } else {
        await addDropdownMenu();
    }

}

export const addDropdownMenu = async () => {

    const dropdownMenu = document.getElementsByClassName('swv-dropdown-list')[0];

    if (!dropdownMenu) {

        setTimeout(() => {
            addDropdownMenu();
        }, 100);

    } else {

        const customArray = document.getElementsByClassName('custom-add-item');

        if (customArray.length > 0) {
            return;
        }

        const marketplaceElement = document.createElement('li');
        marketplaceElement.className = "swv-dropdown-list-item fst-normal custom-add-item";
        marketplaceElement.innerHTML = `<a href="/marketplace" class="text-decoration-none" style="font-weight: 500"> Marketplace </a>`;

        dropdownMenu.insertBefore(marketplaceElement, dropdownMenu.childNodes[1]);

        const profileElement = document.createElement('li');
        profileElement.className = "swv-dropdown-list-item fst-normal custom-add-item";
        profileElement.innerHTML = `<a href="/profile" class="text-decoration-none" style="font-weight: 500"> My Profile </a>`;

        dropdownMenu.insertBefore(profileElement, dropdownMenu.childNodes[1]);

        const supportElement = document.createElement('li');
        supportElement.className = "swv-dropdown-list-item fst-normal custom-add-item";
        supportElement.innerHTML = `<a href="https://discord.gg/Fw8keubRt9" target="_blank" class="text-decoration-none" style="font-weight: 500"> Support </a>`;

        dropdownMenu.insertBefore(supportElement, dropdownMenu.childNodes[3]);

        const origin = window.location.origin;

        const socialElement = document.createElement('li');
        socialElement.className = "swv-dropdown-list-item fst-normal custom-add-item social-add-item p-0";
        socialElement.innerHTML = `
        <div class="d-flex justify-content-around align-items-center w-100">
            <div class="col-6">
                <a href="https://discord.gg/Fw8keubRt9" target="_blank">
                    <img src="${origin}/images/discord.png" style="width: 20px; height: 20px;" />
                </a>
            </div>
            <div class="col-6">
                <a href="https://twitter.com/Mimirlab" target="_blank">
                    <img src="${origin}/images/twitter.png" style="width: 20px; height: 20px;" />
                </a>
            </div>
        </div>
        `;

        dropdownMenu.insertBefore(socialElement, dropdownMenu.childNodes[7]);


    }

};

export const connectDiscord = async (discord_code) => {

    const commonStore = useCommonStore();
    const webApiUrl = commonStore.webApiUrl;

    const userToken = window.$cookies.get('userToken');

    const status = await axios({
        method: 'post',
        url: webApiUrl + '/auth/connect_discord',
        headers: {
            'Authorization': 'Bearer ' + userToken
        },
        data: {
            discord_code: discord_code
        }
    }).then(() => {
        return true;
    }).catch((error) => {
        console.log(error);
        return false;
    });

    return status;

}

export const connectTelegram = async (telegram_data, userToken) => {

    const commonStore = useCommonStore();
    const webApiUrl = commonStore.webApiUrl;

    const status = await axios({
        method: 'post',
        url: webApiUrl + '/auth/connect_telegram',
        headers: {
            'Authorization': 'Bearer ' + userToken
        },
        data: telegram_data
    }).then((response) => {

        const data = response.data;

        ElMessage({
            message: data.Status.Desc,
            type: 'success',
            duration: 1500,
            showClose: true,
        });

        return true;

    }).catch((error) => {

        ElMessage({
            message: error.response.data.Status.Desc,
            type: 'error',
            duration: 1500,
            showClose: true,
        });

        return false;

    });

    return status;

}