import { Transaction, VersionedTransaction } from "@solana/web3.js";
import { filter } from "lodash";
import { useAnchorWallet } from "solana-wallets-vue";

export const connectWallet = () => {
    const wallet = useAnchorWallet();
    return wallet.value;
};

export const groupBy = (array, key) => {

    const groupedData = {}

    if (key == '1D') {

        array.forEach((item) => {

            const dateKey = Date.UTC(
                parseInt(item.Date.slice(0, 4)),
                parseInt(item.Date.slice(5, 7)) - 1,
                parseInt(item.Date.slice(8, 10)),
            );

            // 如果日期在 groupedData 中不存在，则创建一个新数组来存储数据
            if (!groupedData[dateKey]) {
                groupedData[dateKey] = [];
            }

            // 将数据添加到相应的日期分组中
            groupedData[dateKey].push(item);

        })

    } else if (key == '1W') {

        array.forEach((item) => {

            const utcDate = new Date(item.utcDate * 1000); // 使用 UTC 时间戳创建 Date 对象

            // 计算当前日期所在周的星期一日期
            const startOfWeek = new Date(utcDate);
            startOfWeek.setUTCDate(utcDate.getUTCDate() - utcDate.getUTCDay() + 1);

            const startOfWeekKey = Date.UTC(
                startOfWeek.getUTCFullYear(),
                startOfWeek.getUTCMonth(),
                startOfWeek.getUTCDate()
            );

            // 将舍入后的时间作为键，将数据项添加到相应的分组
            if (!groupedData[startOfWeekKey]) {
                groupedData[startOfWeekKey] = [];
            }
            groupedData[startOfWeekKey].push(item);

        });


    } else if (key == '1M') {

        array.forEach((item) => {

            const utcDate = new Date(item.utcDate * 1000); // 使用 UTC 时间戳创建 Date 对象

            const startOfMonthKey = Date.UTC(
                utcDate.getUTCFullYear(),
                utcDate.getUTCMonth(),
                1
            );

            // 将舍入后的时间作为键，将数据项添加到相应的分组
            if (!groupedData[startOfMonthKey]) {
                groupedData[startOfMonthKey] = [];
            }
            groupedData[startOfMonthKey].push(item);

        });


    } else {

        const intervalInMinutes = parseInt(key);

        array.forEach((item) => {

            const utcDate = Date.UTC(
                parseInt(item.Date.slice(0, 4)),
                parseInt(item.Date.slice(5, 7)) - 1,
                parseInt(item.Date.slice(8, 10)),
                parseInt(item.Date.slice(11, 13)),
                parseInt(item.Date.slice(14, 16)),
                parseInt(item.Date.slice(17, 19))
            );

            const roundedTime = Math.floor(utcDate / (intervalInMinutes * 60 * 1000)) * (intervalInMinutes * 60 * 1000); // 将时间戳舍入到最接近的指定间隔的时间点

            // 将舍入后的时间作为键，将数据项添加到相应的分组
            if (!groupedData[roundedTime]) {
                groupedData[roundedTime] = [];
            }
            groupedData[roundedTime].push(item);

        });
    }

    const result = []

    for (const key of Object.keys(groupedData)) {
        result.push({
            Date: new Date(parseInt(key)).toISOString().slice(0, 10),
            Entry: groupedData[key][0].Entry,
            Exit: groupedData[key][groupedData[key].length - 1].Exit,
            utcDate: parseInt(key) / 1000
        });
    }

    return result;

};

export const ellipsisText = (str) => {
    if (str.length > 35) {
        return str.substr(0, 5) + '...' + str.substr(str.length - 5, str.length);
    }
    return str;
}

export const getRawTransaction = (encodedTransaction) => {

    let recoveredTransaction;

    try {
        recoveredTransaction = Transaction.from(
            Buffer.from(encodedTransaction, 'base64')
        );
    } catch (error) {
        recoveredTransaction = VersionedTransaction.deserialize(
            Buffer.from(encodedTransaction, 'base64')
        );
    }

    return recoveredTransaction;
}

export const returnTextClass = (num) => {

    if (num > 0) {
        return 'text-positive';
    } else if (num < 0) {
        return 'text-negative';
    } else {
        return 'text-light';
    }

}

export const goToPage = (path, mode='normal') => {
    if (mode == 'normal') {
        window.location.href = path;
    } else if (mode == 'blank') {
        window.open(path, '_blank');
    }
}

export const timer = (portfolio) => {

    if (portfolio.start_time) {

        const now = new Date();
        const start  = new Date(portfolio.start_time);

        const period = now - start;

        // Calculate the days, hours, minutes, and seconds
        let day = Math.floor(period / (1000 * 60 * 60 * 24));
        let hr = Math.floor((period % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let min = Math.floor((period % (1000 * 60 * 60)) / (1000 * 60));
        let sec = Math.floor((period % (1000 * 60)) / 1000);

        day = day > 9 ? day : '0' + day;
        hr = hr > 9 ? hr : '0' + hr;
        min = min > 9 ? min : '0' + min;
        sec = sec > 9 ? sec : '0' + sec;

        portfolio.run_time = '';
        portfolio.run_time +=  day > 0 ? `${day}D ` : '';
        portfolio.run_time +=  hr > 0 || day > 0 ? `${hr}H ` : '';
        portfolio.run_time +=  min > 0 || hr > 0 || day > 0 ? `${min}M ` : '';
        portfolio.run_time +=  (sec > 0 || min > 0) &&  day == 0 ? `${sec}S` : '';

        setTimeout(() => {
            timer(portfolio);
        }, 1000);

    }

}

export const dateToString = (date) => {

    let year = date.getFullYear();

    let month = date.getMonth() + 1;
    if (month < 10) {
        month = `0${month}`;
    }

    let day = date.getDate();
    if (day < 10) {
        day = `0${day}`;
    }

    let hour = date.getHours();
    if (hour < 10) {
        hour = `0${hour}`;
    }

    let minute = date.getMinutes();
    if (minute < 10) {
        minute = `0${minute}`;
    }

    return `${year}-${month}-${day} ${hour}:${minute}`;

}

export const cumulativeReturns = (data, period = null) => {

    let history = [];

    if (period) {

        const now = new Date();
        const start = now.setDate(now.getDate() - period);

        history = filter(data, (item) => {
            return new Date(item.exit_time).getTime() >= start;
        });

    } else {
        history = data;
    }

    let cumulativeReturn = 1;

    for (const trade of history) {
        cumulativeReturn *= (1 + trade.returns);
    }

    const result = ((cumulativeReturn - 1) * 100);

    return result;

}

export const historyReturns = async (data) => {

    const benchmarkReturns = [];

    const strategyReturns = [];
    let strategyCumulativeReturn = 1;

    const benchmarkEntryPrice = parseFloat(data[0].entry_price)

    for (const trade of data) {

        const time = new Date(trade.exit_time).getTime();
        strategyCumulativeReturn *= (1 + trade.returns);
        
        strategyReturns.push([
            time,
            Math.floor(((strategyCumulativeReturn - 1) * 100) * 1000) / 1000
        ]);

        benchmarkReturns.push([
            time,
            Math.floor(((parseFloat(trade.exit_price) / benchmarkEntryPrice - 1) * 100) * 1000) / 1000
        ]);

    }

    return {
        strategyReturns: strategyReturns,
        benchmarkReturns: benchmarkReturns
    };

}

export const getBacktestStatsTooltip = (label) => {

    const tooltip_array = [
        {
            label: 'trading_pair',
            tooltip: 'The combination of two currencies that are being traded against each other.'
        },
        {
            label: 'Start',
            tooltip: 'The beginning date of the backtesting period.'
        },
        {
            label: 'End',
            tooltip: 'The ending date of the backtesting period.'
        },
        {
            label: 'Start Value',
            tooltip: 'The initial amount of capital used to start the trading strategy.'
        },
        {
            label: 'End Value',
            tooltip: 'The total amount of capital at the end of the trading strategy, after all profits and losses.'
        },
        {
            label: 'Total Fees Paid',
            tooltip: 'The cumulative amount of transaction fees paid throughout the trading period.'
        },
        {
            label: 'Total Return [%]',
            tooltip: `The overall percentage change in the strategy's value from start to end.`
        },
        {
            label: 'Benchmark Return [%]',
            tooltip: 'The percentage return of a benchmark for comparison, indicating how well the strategy performed against a standard measure.'
        },
        {
            label: 'Sharpe Ratio',
            tooltip: 'Measures the performance of an investment compared to a risk-free asset, after adjusting for its risk, indicating how much excess return you are receiving for the extra volatility that you endure for holding a riskier asset.'
        },
        {
            label: 'Sortino Ratio',
            tooltip: `Similar to the Sharpe Ratio but focuses only on the downside risk, offering insight into the risk-adjusted return of the investment's negative volatility.`
        },
        {
            label: 'Max Drawdown [%]',
            tooltip: 'The largest percentage drop in value from a peak to a trough during the backtesting period, indicating the highest potential loss.'
        },
        {
            label: 'Max Drawdown Duration',
            tooltip: 'The length of time the strategy experienced its largest drawdown, showing how long it took to recover from the biggest loss.'
        },
        {
            label: 'Total Trades',
            tooltip: 'The total number of buy and sell transactions executed within the backtesting period.'
        },
        {
            label: 'Win Rate [%]',
            tooltip: 'The percentage of trades that resulted in a profit out of the total number of trades made.'
        },
        {
            label: 'Best Trade [%]',
            tooltip: 'The percentages of the most profitable trade from a single trade, respectively.'
        },
        {
            label: 'Worst Trade [%]',
            tooltip: 'The percentages of the most significant loss from a single trade, respectively.'
        },
        {
            label: 'Avg Winning Trade [%]',
            tooltip: 'The average percentage gain of profitable trades, respectively.'
        },
        {
            label: 'Avg Losing Trade [%]',
            tooltip: 'The average percentage loss of unprofitable trades, respectively.'
        },
        {
            label: 'Calmar Ratio',
            tooltip: 'A measure of the return earned per unit of downside risk, comparing the annual return rate to the maximum drawdown.'
        },
        {
            label: 'Omega Ratio',
            tooltip: 'Evaluates the probability of achieving a target return level, comparing the likelihood of gains versus losses.'
        }
    ];

    const tooltip = tooltip_array.find((item) => {
        return item.label == label;
    });

    return tooltip?.tooltip;

};

export const SPOT_TRADING_PAIR_OPTIONS = [
    {
        label: 'SOL / USDC',
        value: 'SOLUSD',
        address: 'So11111111111111111111111111111111111111112',
        image: 'sol',
        start: new Date('2022-02-04T00:00:00Z')
    },
    {
        label: 'wBTC / USDC',
        value: 'WBTCUSD',
        address: '3NZ9JMVBmGAqocybic2c7LQCJScmgsAZ6vQqTDzcqmJh',
        image: 'wbtc',
        start: new Date('2022-02-04T00:00:00Z')
    },
    {
        label: 'wETH / USDC',
        value: 'WETHUSD',
        address: '7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs',
        image: 'weth',
        start: new Date('2022-02-04T00:00:00Z')
    },
    {
        label: 'PYTH / USDC',
        value: 'PYTHUSD',
        address: 'HZ1JovNiVvGrGNiiYvEozEVgZ58xaU3RKwX8eACQBCt3',
        image: 'pyth',
        start: new Date('2023-11-20T00:00:00Z')
    },
    {
        label: 'JUP / USDC',
        value: 'JUPUSD',
        address: 'JUPyiwrYJFskUPiHa7hkeR8VUtAeFoSYbKedZNsDvCN',
        image: 'jup',
        start: new Date('2024-02-01T00:00:00Z')
    },
    {
        label: 'BONK / USDC',
        value: 'BONKUSD',
        address: 'DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263',
        image: 'bonk',
        start: new Date('2023-02-07T00:00:00Z')
    },
    {
        label: 'WIF / USDC',
        value: 'WIFUSD',
        address: 'EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm',
        image: 'wif',
        start: new Date('2024-01-25T00:00:00Z')
    },
    {
        label: 'WEN / USDC',
        value: 'WENUSD',
        address: 'WENWENvqqNya429ubCdR81ZmD69brwQaaBYY6p3LCpk',
        image: 'wen',
        start: new Date('2024-02-08T00:00:00Z')
    },
];

export const PERPS_TRADING_PAIR_OPTIONS = [
    {
        label: 'SOL-PERP',
        value: 'SOLUSD',
        image: 'sol',
        start: new Date('2022-02-04T00:00:00Z'),
        max_leverage: 3
    },
    {
        label: 'BTC-PERP',
        value: 'BTCUSD',
        image: 'btc',
        start: new Date('2022-02-04T00:00:00Z'),
        max_leverage: 3
    },
    {
        label: 'ETH-PERP',
        value: 'ETHUSD',
        image: 'eth',
        start: new Date('2022-02-04T00:00:00Z'),
        max_leverage: 3
    },
];

export const oldMintTimeCountdown = (strategy) => {

    const now = new Date();
    const start = new Date(strategy.mint_date);
    const end = new Date(strategy.end_date);

    if (now >= start && now < end) {

        var countdownCode = 1;
        var countdownText = 'ENDS IN';
        var remainingTime = end - now;

    } else if (now < start) {

        var countdownCode = 2;
        var countdownText = 'STARTS IN';
        var remainingTime = start - now;

    }

    // Calculate the days, hours, minutes, and seconds
    let day = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
    let hr = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let min = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
    let sec = Math.floor((remainingTime % (1000 * 60)) / 1000);

    day = day > 9 ? day : '0' + day;
    hr = hr > 9 ? hr : '0' + hr;
    min = min > 9 ? min : '0' + min;
    sec = sec > 9 ? sec : '0' + sec;

    strategy.countdown = {
        day: day,
        hr: hr,
        min: min,
        sec: sec,
        countdownCode: countdownCode,
        countdownText: countdownText
    }

    setTimeout(() => {
        oldMintTimeCountdown(strategy);
    }, 1000);

}

export const mintTimeCountdown = (group) => {

    let countdownText;
    let countdownCode;

    const now = new Date();

    const guards = group.guards;
    const base = new Date(Number(guards.startDate.date) * 1000);
    const end = guards.endDate != null ? new Date(Number(guards.endDate.date) * 1000) : null;

    let remainingTime;

    if (group.label == 'wl' || group.label == 'og') {

        if (now >= base && now <= end) {

            countdownText = 'ENDS IN';
            countdownCode = 1;
            remainingTime = end - now;


        } else if (now > end) {

            remainingTime = 0;
            countdownText = 'ENDED';
            countdownCode = 2;

        } else {

            remainingTime = base - now;
            countdownText = 'STARTS AFTER';
            countdownCode = 3;

        }

    } else {

        if (now >= base) {

            countdownText = '';
            countdownCode = 1;
            remainingTime = 0;

        } else {

            remainingTime = base - now;
            countdownText = 'STARTS AFTER';
            countdownCode = 3;

        }

    }

    // Calculate the days, hours, minutes, and seconds
    let day = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
    let hr = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let min = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
    let sec = Math.floor((remainingTime % (1000 * 60)) / 1000);

    day = day > 9 ? day : '0' + day;
    hr = hr > 9 ? hr : '0' + hr;
    min = min > 9 ? min : '0' + min;
    sec = sec > 9 ? sec : '0' + sec;

    const assignInfo = {
        day: day,
        hr: hr,
        min: min,
        sec: sec,
        countdownCode: countdownCode,
        countdownText: countdownText
    }

    group.countdown = assignInfo;


    setTimeout(() => {
        mintTimeCountdown(group);
    }, 1000);

}