import axios from 'axios';
import { useCommonStore } from '@/store';
import { useWallet } from "solana-wallets-vue";
import { getRawTransaction, getSolBalance, getTokenBalance, useWorkspace } from '@/composables';
import { ElMessage } from 'element-plus';
import { ComputeBudgetProgram, Connection, Transaction } from '@solana/web3.js';

export const createPosition = async (
    password, strategy_address, nft_address,
    create_process, showCreatePositionDialog
) => {

    const commonStore = useCommonStore();
    const webApiUrl = commonStore.webApiUrl;

    const userToken = window.$cookies.get('userToken');

    const createResult = await axios({
        method: 'post',
        url: webApiUrl + '/portfolio/create',
        headers: {
            'Authorization': 'Bearer ' + userToken
        },
        data: {
            password: password,
            strategy_address: strategy_address,
            nft_address: nft_address
        }
    }).then((response) => {
        create_process.value = 1;
        return response.data.Result;
    }).catch((error) => {
        console.log(error);
    });

    // console.log(createResult);

    if (createResult) {

        // Transfer NFT to strategy
        const { publicKey, sendTransaction } = useWallet();

        const commonStore = useCommonStore();
        const solanaNetwork = commonStore.solanaNetwork;
        const clusterApiUrl = commonStore[`${solanaNetwork}RpcEndpoint`];
        const connection = new Connection(clusterApiUrl, 'confirmed');

        // const wallet = connectWallet();
        const from_address = publicKey.value.toBase58();
        const to_address = createResult.semi_custodial_wallet_address;
        const token_address = createResult.user_nft_address;

        const shyftApiUrl = process.env.VUE_APP_SHYFT_API_URL;
        const shyftApiKey = process.env.VUE_APP_SHYFT_API_KEY;

        const network = commonStore.solanaNetwork;

        try {

            const encoded_transaction = await axios({
                method: 'post',
                url: shyftApiUrl + '/nft/transfer_detach',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': shyftApiKey
                },
                data: {
                    network: network,
                    from_address: from_address,
                    to_address: to_address,
                    token_address: token_address
                }
            }).then((response) => {
                return response.data.result.encoded_transaction;
            }).catch((error) => {
                console.log(error);
            });

            if (encoded_transaction) {

                const tx = new Transaction();

                tx.add(
                    ComputeBudgetProgram.setComputeUnitLimit({
                        units: 150_000
                    })
                );

                tx.add(
                    ComputeBudgetProgram.setComputeUnitPrice({
                        microLamports: 1500000
                    })
                );

                const recoveredTransaction = getRawTransaction(encoded_transaction);

                for (const ix of recoveredTransaction.instructions) {
                    if (ix.programId.toBase58() != 'ComputeBudget111111111111111111111111111111') {
                        tx.add(ix);
                    };
                }

                const latestBlockhash = await connection.getLatestBlockhash('confirmed');
                tx.recentBlockhash = latestBlockhash.blockhash;
                tx.feePayer = publicKey.value;

                const txid = await sendTransaction(
                    tx,
                    connection,
                    {
                        skipPreflight: false,
                        preflightCommitment: "confirmed"
                    }
                );
                console.log(txid);

                const result = await connection.confirmTransaction(txid, 'confirmed');

                if (result.value.err) {

                    ElMessage({
                        type: 'error',
                        message: 'Error creating position',
                        duration: 5000,
                        showClose: true
                    });

                    await axios({
                        method: 'post',
                        url: webApiUrl + '/portfolio/delete',
                        headers: {
                            'Authorization': 'Bearer ' + userToken
                        },
                        data: {
                            nft_address: token_address,
                            semi_custodial_wallet_address: to_address
                        }
                    });

                    create_process.value = 0;
                    showCreatePositionDialog.value = false;
    
                } else {
    
                    create_process.value = 2;
    
                }

            }

        } catch (error) {

            const error_msg = error.message;

            if (error_msg.includes('Transaction was not confirmed in')) {

                create_process.value = 2;

            } else {

                ElMessage({
                    type: 'error',
                    message: error.message,
                    duration: 1500,
                    showClose: true
                });

                await axios({
                    method: 'post',
                    url: webApiUrl + '/portfolio/delete',
                    headers: {
                        'Authorization': 'Bearer ' + userToken
                    },
                    data: {
                        nft_address: token_address,
                        semi_custodial_wallet_address: to_address
                    }
                });

                create_process.value = 0;
                showCreatePositionDialog.value = false;

            }

        }

    } else {

        ElMessage({
            type: 'error',
            message: 'Error creating position',
            duration: 1500,
            showClose: true
        });

        create_process.value = 0;
        showCreatePositionDialog.value = false;

    }

}

export const getUserPortfolio = async () => {

    const commonStore = useCommonStore();
    const webApiUrl = commonStore.webApiUrl;

    const userToken = window.$cookies.get('userToken');

    const data = await axios({
        method: 'get',
        url: webApiUrl + '/portfolio/my_portfolio',
        headers: {
            'Authorization': 'Bearer ' + userToken
        },
    }).then((response) => {
        return response.data.Result;
    }).catch((error) => {
        console.log(error);
        if (error.response.data.Status.Code == 403) {
            window.location.href = '/';
        }
    });

    return data;

};

export const getUserPortfolioHistory = async (page) => {

    const commonStore = useCommonStore();
    const webApiUrl = commonStore.webApiUrl;

    const userToken = window.$cookies.get('userToken');

    const data = await axios({
        method: 'get',
        url: webApiUrl + '/portfolio/my_portfolio_history',
        headers: {
            'Authorization': 'Bearer ' + userToken
        },
        params: {
            page: page
        }
    }).then((response) => {
        return response.data.Result;
    }).catch((error) => {
        console.log(error);
        if (error.response.data.Status.Code == 403) {
            window.location.href = '/';
        }
    });

    return data;
};

export const closePosition = async (password, nft_address) => {

    const commonStore = useCommonStore();
    const webApiUrl = commonStore.webApiUrl;

    const userToken = window.$cookies.get('userToken');

    const closeResult = await axios({
        method: 'post',
        url: webApiUrl + '/portfolio/close',
        headers: {
            'Authorization': 'Bearer ' + userToken
        },
        data: {
            password: password,
            nft_address: nft_address
        }
    }).then((response) => {
        return true;
    }).catch((error) => {

        if (error.response.status == 999) {

            ElMessage({
                type: 'error',
                message: 'Please sell all positions first.',
                duration: 5000,
                showClose: true
            });

        } else if (error.response.status == 502) {

            ElMessage({
                type: 'error',
                message: 'Timeout Error, please try again.',
                duration: 5000,
                showClose: true

            });

        } else {

            ElMessage({
                type: 'error',
                message: error.response?.data?.Status?.Desc,
                duration: 5000,
                showClose: true

            });

        }

        return false;
    });

    return closeResult;

}

export const activatePosition = async (semi_custodial_wallet_address) => {

    const commonStore = useCommonStore();
    const webApiUrl = commonStore.webApiUrl;

    const userToken = window.$cookies.get('userToken');

    const result = await axios({
        method: 'post',
        url: webApiUrl + '/portfolio/activate',
        headers: {
            'Authorization': 'Bearer ' + userToken
        },
        data: {
            semi_custodial_wallet_address: semi_custodial_wallet_address
        }
    }).then((response) => {
        const result = response.data.Result;
        return result.start_time;
    }).catch((error) => {

        const error_msg = error.response.data;

        ElMessage({
            type: 'error',
            message: error_msg.Status.Desc,
            duration: 5000,
            showClose: true

        });

        return null;

    });

    return result;

}

export const depositToken = async (to_address, amount, token_address) => {

    if (window.$cookies.isKey('userToken') == false) {
        ElMessage({
            type: 'error',
            message: 'Token expired, please connect wallet again.',
            duration: 5000,
            showClose: true
        });
        return false;
    }

    const { publicKey, sendTransaction } = useWallet();
    const from_address = publicKey.value.toBase58();

    const shyftApiUrl = process.env.VUE_APP_SHYFT_API_URL;
    const shyftApiKey = process.env.VUE_APP_SHYFT_API_KEY;

    const commonStore = useCommonStore();
    const network = commonStore.solanaNetwork;

    let encoded_transaction;
    let depositType;

    if (token_address == 'So11111111111111111111111111111111111111112') {

        encoded_transaction = await axios({
            method: 'post',
            url: shyftApiUrl + '/wallet/send_sol',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': shyftApiKey
            },
            data: {
                network: network,
                from_address: from_address,
                to_address: to_address,
                amount: parseFloat(amount)
            }
        }).then((response) => {
            return response.data.result.encoded_transaction;
        }).catch((error) => {
            console.log(error);
        });

        depositType = 'fee';

    } else {

        encoded_transaction = await axios({
            method: 'post',
            url: `${shyftApiUrl}/token/transfer_detach`,
            headers: {
                'x-api-key': shyftApiKey,
                'Content-Type': 'application/json'
            },
            data: {
                network: network,
                from_address: from_address,
                to_address: to_address,
                token_address: token_address,
                amount: parseFloat(amount)
            }
        }).then((res) => {
            return res.data.result.encoded_transaction;
        }).catch((err) => {
            console.log(err);
        });

        depositType = 'fund'

    }

    if (encoded_transaction) {

        const tx = new Transaction();

        tx.add(
            ComputeBudgetProgram.setComputeUnitLimit({
                units: 50_000
            })
        );

        tx.add(
            ComputeBudgetProgram.setComputeUnitPrice({
                microLamports: 1000000
            })
        );

        const recoveredTransaction = getRawTransaction(encoded_transaction);

        for (const ix of recoveredTransaction.instructions) {
            if (ix.programId.toBase58() != 'ComputeBudget111111111111111111111111111111') {
                tx.add(ix);
            };
        }

        try {

            const { connection } = useWorkspace();

            const latestBlockhash = await connection.getLatestBlockhash('confirmed');
            tx.recentBlockhash = latestBlockhash.blockhash;
            tx.feePayer = publicKey.value;

            const txid = await sendTransaction(
                tx,
                connection
            );
            console.log(txid);

            let retry = 0;
            let deposit_status = false;

            while (true) {

                await new Promise((resolve) => setTimeout(resolve, 5000));

                const confirmedTransaction = await connection.getSignatureStatus(txid);
                console.log(confirmedTransaction);
                const status = confirmedTransaction.value?.confirmationStatus;

                if (status === 'confirmed' || status === 'finalized') {
                    deposit_status = true;
                    await depositApi(to_address, amount, depositType);
                    break;
                } else {
                    retry++;
                    if (retry > 20) {
                        throw new Error('Transaction timout, not sure if it is successful. Please check your wallet.');
                    }
                }

            }

            return deposit_status;

        } catch (error) {

            ElMessage({
                type: 'error',
                message: error.message,
                duration: 5000,
                showClose: true
            });

            return false;

        }
    }

}

export const depositApi = async (to_address, amount, type) => {

    const commonStore = useCommonStore();
    const webApiUrl = commonStore.webApiUrl;

    const userToken = window.$cookies.get('userToken');

    const depositResult = await axios({
        method: 'post',
        url: webApiUrl + '/portfolio/deposit',
        headers: {
            'Authorization': 'Bearer ' + userToken
        },
        data: {
            semi_custodial_wallet_address: to_address,
            amount: amount,
            type: type
        }
    }).then(() => {

        ElMessage({
            type: 'success',
            message: `Successfully deposited ${amount}`,
            duration: 1500,
            showClose: true
        });

        return true;

    }).catch((error) => {
        console.log(error);

        ElMessage({
            type: 'error',
            message: error.response.data.Status.Desc,
            duration: 5000,
            showClose: true

        })

        return false;
    });

    return depositResult
}

export const positionDetail = async (semi_custodial_wallet_address) => {

    const commonStore = useCommonStore();
    const webApiUrl = commonStore.webApiUrl;

    const userToken = window.$cookies.get('userToken');

    const data = await axios({
        method: 'get',
        url: webApiUrl + '/portfolio/detail',
        headers: {
            'Authorization': 'Bearer ' + userToken
        },
        params: {
            semi_custodial_wallet_address: semi_custodial_wallet_address
        }
    }).then((response) => {
        return response.data.Result;
    }).catch((error) => {
        console.log(error);
        if (error.response.data.Status.Code == 403) {
            window.location.href = '/';
        } else {
            window.location.href = '/portfolio';
        }
    });

    return data;

}

export const getPortfolioBalance = async (semi_custodial_wallet_address, token_addresses) => {

    const balance_info = {};

    for (const address of token_addresses) {

        if (address == 'So11111111111111111111111111111111111111112') {

            const sol_balance = await getSolBalance(semi_custodial_wallet_address);

            const final_balance = sol_balance.balance;

            balance_info[address] = {
                balance: final_balance > 0 ? final_balance : 0,
                info: {
                    symbol: 'SOL',
                    image: 'sol',
                    float: 2,
                    decimals: 9
                }
            };

        } else {

            const token_balance = await getTokenBalance(semi_custodial_wallet_address, address);

            balance_info[address] = {
                balance: token_balance.balance,
                info: token_balance.info
            };

        }

    }

    return balance_info;

}

export const groupPortfolioStats = async (user_portfolio) => {

    let total_profit = 0;
    let trade_times = 0;
    let win_times = 0;
    let total_volumes = 0;

    // 計算各策略的總餘額
    const strategy_balance = {};
    const token_balance = {};

    for (const strategy of user_portfolio) {

        total_profit += strategy.cum_pnl ?? 0;
        trade_times += strategy.trade_history.length;
        win_times += strategy.trade_history.filter(item => item.pnl > 0).length;
        total_volumes += strategy.trading_volume ?? 0;

        const strategy_address = strategy.semi_custodial_wallet_address;

        strategy_balance[strategy_address] = {
            name: strategy.name,
            image_uri: strategy.image_uri,
            balance: 0
        };

        for (const balance of strategy.balance_info) {

            const token_address = balance.token;

            const token_num = balance.balance;
            const token_price = balance.price;

            strategy_balance[strategy_address].balance += token_num * token_price;

            if (token_balance[token_address]) {
                token_balance[token_address].volume += token_num;
                token_balance[token_address].price = token_price;
            } else {
                token_balance[token_address] = {
                    volume: token_num,
                    price: token_price,
                    symbol: balance.info.symbol.toUpperCase(),
                    image: balance.info.image,
                    float: balance.info.float,
                    decimals: balance.info.decimals
                };
            }
        }
    }

    return {
        stats: {
            total_profit: total_profit,
            trade_times: trade_times,
            win_times: win_times,
            win_rate: trade_times > 0 ? (win_times / trade_times) * 100 : 0,
            total_volumes: total_volumes
        },
        strategy_balance: Object.keys(strategy_balance).map(key => {
            return strategy_balance[key];
        }),
        token_balance: Object.keys(token_balance).map(key => {
            return token_balance[key];
        })
    }

}